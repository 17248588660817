import MapView from "./components/map/MapView";
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import './translate/i18n';
import theme from "./theme/bettairTheme";
import React from "react";
import {SnackbarProvider} from "notistack";
import {StylesProvider, ThemeProvider} from "@mui/styles";
import {DialogMessageProvider} from "./hooks/useMessageDialog";
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet";

function App() {
    const {i18n} = useTranslation();

    return (
        <StylesProvider injectFirst>
            <Helmet>
                <html lang={i18n.language} />
                <meta httpEquiv="Content-Language" content={i18n.language} />
                <meta name="language" content={i18n.language} />
                <meta name="google" content="notranslate" />
            </Helmet>

            <SnackbarProvider maxSnack={8}>
                <Router>
                    <Switch>
                        <Route path="*">
                            <ThemeProvider theme={theme}>
                                <DialogMessageProvider>
                                    <MapView/>
                                </DialogMessageProvider>
                            </ThemeProvider>
                        </Route>
                    </Switch>
                </Router>
            </SnackbarProvider>
        </StylesProvider>
    );
}

export default App;